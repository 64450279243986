import jwt_decode from "jwt-decode";

// return the user data from the session storage
export const getUser = () => {
  const userStr = localStorage.getItem('api_token');
  if (userStr && userStr !== 'undefined') {
     return JSON.parse(userStr);
  }  else {
    // removeUserSession();
    setUserSession(null)
    setTokenExpires(null)
  }
}

// remove the token and user from the session storage
export const removeUserSession = () => {
  setUserSession(null);
  setTokenExpires(null);
  setCustomerName(null);
  setMobileNumber(null);
  // localStorage.clear();
}
// set the token and user from the session storage
export const setUserSession = (user) => {
  localStorage.setItem('api_token', JSON.stringify(user));
}

export const setCustomerName = (user) => {
  localStorage.setItem('username', JSON.stringify(user));
}

export const setMobileNumber = (user) => {
  localStorage.setItem('mobileno', JSON.stringify(user));
}

export const setGuestRole = (guest) => {
  localStorage.setItem('guestData', JSON.stringify(guest));
}

export const setTokenExpires = (user) => {
  localStorage.setItem('expiresAt', JSON.stringify(user));
}

export const getCustomerName = () => {
  const userStr = localStorage.getItem('username');
  if (userStr && userStr !== 'undefined') {
     return JSON.parse(userStr);
  }  else {
    setCustomerName(null)
  }
}

export const getMobileNumber = () => {
  const userStr = localStorage.getItem('mobileno');
  if (userStr && userStr !== 'undefined') {
     return JSON.parse(userStr);
  }  else {
    setMobileNumber(null)
  }
}

export const getTokenExpires = () => {
  const expiresAt = localStorage.getItem('expires_in');
  if (expiresAt) return JSON.parse(expiresAt);
  else return null;
}

export const getUserRole = () => {
  const decrypted = decryptToken();
  if (decrypted?.rle) return decrypted?.rle
  else return null;
}

export const getGuestRole = () => {
  const data = localStorage.getItem('guestData');
  if (data && data !== 'undefined') {
     return JSON.parse(data);
  }
}

export const removeGuestRole = () => {
  localStorage.removeItem('guestData');
  localStorage.clear();
  window.location.href = "/user/sim-auth"
}

export const getUserName = () => {
  const decrypted = decryptToken();
  if (decrypted?.une) return decrypted?.une
  else return null;
}

const decryptToken = () => {
  const token = getUser();
  if (token) {
    return jwt_decode(token);
  }
  return null
}



